import React, { useState } from 'react';
// Libraries
import { FormattedMessage } from 'react-intl';
import { CustomDropdown as Dropdown } from 'golf-ui-components';

export interface Props {
  handlegetChartsInfo: (selectedYear: number) => void
}

export const DashboardActionsBar : React.FC<Props> = ({ handlegetChartsInfo }) => {
  // States
  const [selectedYear, setSelectedYear] = useState((new Date()).getFullYear());

  const getValidYears = () => {
  	let year = new Date().getFullYear();
    const validYears = [];

  	while (year > 2020){
  		validYears.push({ id: year, title: year });
  		year = year - 1;
    }

    return validYears;
  };

  return (
  <div
    className="d-flex justify-content-end align-items-center header-action-bar global-filter dashboard-actionbar"
  >
	  <span className="fs-6 me-5">
		  	<FormattedMessage id='DASHBOARD.YEAR' />
	  </span>
	  <Dropdown
		  name='programStatus'
		  options={getValidYears()}
		  value={selectedYear.toString()}
		  onChange={(year) => {
		  	setSelectedYear(year);
		  	handlegetChartsInfo(year);
		  }}
	  />
  </div>
  );
};

DashboardActionsBar.defaultProps = {};
