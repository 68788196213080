/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { FC } from 'react';
import { useIntl } from 'react-intl';
import { useLayout } from '../core';

const Footer: FC = () => {
  const { classes } = useLayout();
  const intl = useIntl();
  return (
    <div className='py-4 footer d-flex flex-lg-column' id='kt_footer'>
      {/* begin::Container */}
      <div
        className={`${classes.footerContainer} 
				d-flex flex-column flex-md-row align-items-center justify-content-between`}
      >
        {/* begin::Nav */}
        <div className='order-2 text-dark order-md-1'>
          <a
            href={`${process.env.REACT_APP_PUBLIC_URL}/en/datenschutzbestimmungen`}
            className='text-gray-800 text-hover-primary'
            target='_blank'
            rel='noreferrer'
          >
            {intl.formatMessage({ id: 'PRIVACY.POLICY' })}
          </a>
          <span className='text-gray-800 text-hover-primary'> | </span>
          <a
            href={`${process.env.REACT_APP_PUBLIC_URL}/en/impressum`}
            target='_blank'
            rel='noreferrer'
            className='text-gray-800 text-hover-primary'
          >
            {intl.formatMessage({ id: 'IMPRINT' })}
          </a>
        </div>
        {/* end::Nav */}
        {/* begin::Copyright */}
        <div className='order-2 text-dark order-md-1'>
          <a href='#' className='text-gray-800 text-hover-primary'>
            &copy; Copyright {new Date().getFullYear()} TWOWINGS
          </a>
        </div>
        {/* end::Copyright */}
      </div>
      {/* end::Container */}
    </div>
  );
};

export { Footer };
