// Types
import { AxiosResponse } from 'axios';
// Utilities
import { BaseAPI } from '../base';

class AuthProvider extends BaseAPI {
  public constructor() {
    super('admin/users/');
  }

  public getUser(lang = 'de'): Promise<AxiosResponse<any>> {
    const authToken = localStorage.getItem('accessToken');

    return this.httpService.get('profile', {
      headers: {
        'Accept-Language': lang,
        Authorization: `Bearer ${authToken}`,
      },
    });
  }
}

const AuthService = new AuthProvider();

export default AuthService;
