import { library } from '@fortawesome/fontawesome-svg-core';

// fal
import {
  faQuestionCircle,
  faMapMarkerAlt,
  faEnvelope as faFalEnvelope,
  faReply as faFalReply,
  faUnlink,
  faExternalLink,
  faListUl,
  faEdit,
  faSlidersH,
  faTrash,
  faTrashAlt,
  faEye,
  faEyeSlash,
  faCopy,
  faInfoCircle,
  faCheck as falFaCheck,
  faTimes,
  faCheckCircle,
  faCogs,
  faMailbox,
  faArrowToBottom,
  faClipboardListCheck,
  faBallotCheck,
  faArrowAltFromBottom,
  faFileExport as falFileExport,
  faFilePdf,
  faFileWord,
  faDownload,
  faFileImage,
  faPenSquare,  
  faLayerGroup,
  faBusinessTime,
  faEnvelopeOpenDollar,
  faTachometerFast,
  faThLarge,
  faArrowAltDown,
} from '@fortawesome/pro-light-svg-icons';

// fas
import {
  faChevronLeft,
  faChevronRight,
  faEnvelope,
  faReply,
  faPhoneAlt,
  faImage,
  faArrowFromBottom,
  faFileVideo,
  faPlay,
  faArrowAltFromBottom as fasArrowAltFromBottom,
  faHomeAlt,
  faChevronDown,
  faChevronUp,
  faFileExport,
  faHouse,
  faThLarge as fasThLarge,
  faArrowAltDown as fasArrowAltDown,
} from '@fortawesome/pro-solid-svg-icons';

// fab
import {
  faFacebookF,
  faLinkedinIn,
  faYoutube,
} from '@fortawesome/free-brands-svg-icons';

// far
import {
  faCalendarAlt,
  faCheck,
  faEuroSign,
  faAngleRight,
  faAngleLeft,
  faAngleDoubleLeft,
  faAngleDoubleRight,
  faEllipsisV,
  faPlus,
  faRedoAlt,
  faPlayCircle,
  faFile,
  faTh,
  faList,
  faTimes as farTimes,
  faCheckCircle as farCheckCircle,
  faTimesCircle,
  faImage as farImage,
} from '@fortawesome/pro-regular-svg-icons';

library.add(
  // fal
  faQuestionCircle,
  faMapMarkerAlt,
  faAngleRight,
  faAngleLeft,
  faFalEnvelope,
  faFalReply,
  faUnlink,
  faExternalLink,
  faListUl,
  faEdit,
  faSlidersH,
  faTrash,
  faTrashAlt,
  faEye,
  faEyeSlash,
  faCopy,
  faInfoCircle,
  falFaCheck,
  faTimes,
  faCheckCircle,
  faMailbox,
  faCogs,
  faArrowToBottom,
  faClipboardListCheck,
  faBallotCheck,
  faArrowAltFromBottom,
  faFilePdf,
  faFileWord,
  faDownload,
  faFileImage,
  faPenSquare,
  faLayerGroup,
  faBusinessTime,
  faEnvelopeOpenDollar,
  faTachometerFast,
  faThLarge,
  faArrowAltDown,
  // fas
  faEuroSign,
  faChevronLeft,
  faChevronRight,
  faEnvelope,
  faReply,
  faPhoneAlt,
  faImage,
  faArrowFromBottom,
  faFileVideo,
  faPlay,
  fasArrowAltFromBottom,
  faChevronDown,
  faChevronUp,
  faFileExport,
  faHomeAlt,
  faHouse,
  fasThLarge,
  fasArrowAltDown,
  // fab
  faFacebookF,
  faLinkedinIn,
  faYoutube,
  // far
  faAngleLeft,
  faAngleRight,
  faAngleDoubleLeft,
  faAngleDoubleRight,
  faCalendarAlt,
  faCheck,
  faEllipsisV,
  faPlus,
  faRedoAlt,
  faPlayCircle,
  faFile,
  faTh,
  faList,
  farTimes,
  farCheckCircle,
  faTimesCircle, 
  falFileExport,
  farImage,
);
