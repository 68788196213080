import React, { FC } from 'react';
import { FormattedMessage } from 'react-intl';

const Error404: FC = () => (
  <>
    <h1 className="fw-bolder fs-4x text-gray-700 mb-10">
      <FormattedMessage id="404.NOT.FOUND" />
    </h1>
    <div className="fw-bold fs-3 text-gray-400 mb-15">
      <FormattedMessage id="404.TEXT1" />
      <br />
      <FormattedMessage id="404.TEXT2" />
    </div>
  </>
);

export { Error404 };
