import React, { useEffect, useRef } from 'react';
// Libraries
import ApexCharts, { ApexOptions } from 'apexcharts';
// i18n
import { useLang } from '_metronic/i18n/Metronici18n';
// Helpers
import  { toLocalAmount } from 'golf-uitilities';

type Props = {
  className: string;
  chartColor: string;
  chartHeight: string;
  labels: string[];
  info: { [key: string]: string; };
  type: 'amount' | 'count';
  colors: string[],
};

const PieChart: React.FC<Props> = ({
									   className,
									   chartColor,
									   chartHeight,
									   labels,
									   info,
									   type,
									   colors,
								   }) => {
  // Hooks
  const lang = useLang();
  const chartRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    if (!chartRef.current) {
      return;
    }

    const chart = new ApexCharts(chartRef.current,
      chartOptions(chartColor, chartHeight, labels, info, type, colors, lang));

    if (chart) {
      chart.render();
    }

    // eslint-disable-next-line consistent-return
    return () => {
      if (chart) {
        chart.destroy();
      }
    };
  }, [chartRef, info]);

  return (
    <div className={className}>
      <div ref={chartRef} className="mixed-widget-7-chart card-rounded-bottom" />
    </div>
  );
};

const setLabel = (values: string[], labels: string[], type: string, lang: string) => values.map(
  (value, index) => `${type === 'amount' ? toLocalAmount(value, lang) : value} ${labels[index]}`,
);

const chartOptions = (
  chartColor: string,
  chartHeight: string,
  labels: string[],
  info: { [key: string]: string; },
  type: string,
  colors: string[],
  lang: string,
)
: ApexOptions => {
  const values = Object.values(info);

  return ({
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    series: Object.values(info),
    dataLabels: {
      enabled: true,
      formatter(val: number) {
        return `${val.toFixed(0)}%`;
      },
      textAnchor: 'start',
    },
    chart: {
      fontFamily: 'inherit',
      type: 'pie',
      height: chartHeight,
    },
    legend: {
      show: true,
      position: 'right',
      customLegendItems: setLabel(values, labels, type, lang),
      markers: {
        radius: 0,
      },
    },
    tooltip: {
      y: {
        formatter(value) {
          if (type === 'amount') {
            return toLocalAmount(value, lang);
          }
          return `${value}`;
        },
      },
    },
    colors,
    labels,
  });
};

export default PieChart;
