import React, { FC } from 'react';
// Libraries
import { FormattedMessage } from 'react-intl';
import { Radio } from 'antd';
// Models
import { ChartInfoModel } from 'app/pages/dashboard/models/ChartInfoModel';
// Components
import PieChart from 'app/pages/dashboard/components/DashboardCard/components/PieChart';
import LineChart from 'app/pages/dashboard/components/DashboardCard/components/LineChart';

interface Props {
  title: string;
  pieChartInfo: ChartInfoModel;
  lineChartInfo: ChartInfoModel;
  lineChartColor: string;
  pieChartColors: string[];
  type: 'amount' | 'count';
}

const DashboardCard: FC<Props> = ({
  title,
  pieChartInfo,
  lineChartInfo,
  lineChartColor,
  pieChartColors,
  type,
}) => {
  // States
  const [lineChartType, setLineChartType] = React.useState('accumulative');

  return (
    <div className="bg-white h-100">
      <div className="p-4 text-center card">
        {/* begin::Body */}
        <div className="p-0 card-body d-flex flex-column">
          {/* begin::Chart */}
          <div className="mt-3 mb-7">
            <div className="fs-5">
              <FormattedMessage id="DASHBOARD.DONATION" />
            </div>
            <div className="fw-bold">{title}</div>
          </div>
          <div>
            <div className="row gy-5 g-xl-8">
              <PieChart
                className="mb-4 card-xxl-stretch-50"
                chartColor="primary"
                chartHeight="150px"
                colors={pieChartColors}
                labels={Object.values(pieChartInfo.translation)}
                info={pieChartInfo[type]}
                type={type}
              />
            </div>
          </div>
          {lineChartType === 'accumulative' ? (
            <LineChart
              className="mb-5 card-xxl-stretch-50 mb-xl-4"
              chartColor={lineChartColor}
              chartHeight="300px"
              labels={Object.values(lineChartInfo.translation)}
              data={Object.values(lineChartInfo[type].accumulative)}
              type={type}
            />
          ) : (
            <LineChart
              className="mb-5 card-xxl-stretch-50 mb-xl-4"
              chartColor={lineChartColor}
              chartHeight="300px"
              labels={Object.values(lineChartInfo.translation)}
              data={Object.values(lineChartInfo[type].nonAccumulative)}
              type={type}
            />
          )}
          {/* end::Chart */}
        </div>
        <Radio.Group
          value={lineChartType}
          onChange={(e: any) => { setLineChartType(e.target.value); }}
        >
          <Radio value="accumulative">
            <FormattedMessage id="DASHBOARD.CHART.ACCUMULATIVE" />
          </Radio>
          <Radio value="nonAccumulative" className="mx-5">
            <FormattedMessage id="DASHBOARD.CHART.NON.ACCUMULATIVE" />
          </Radio>
        </Radio.Group>
        {/* end::Body */}
      </div>
    </div>
  );
};

export default DashboardCard;
