import React, { useEffect } from 'react';
// Libraries
import { Redirect, Route, Switch } from 'react-router-dom';
// Helpers
import { toAbsoluteUrl } from '_metronic/helpers';
// Components
import { Languages } from '_metronic/partials/layout/header-menus/Languages';
import { ForgotPassword } from './components/ForgotPassword';
import { Login } from './components/Login';
import { ChangePassword } from './components/ChangePassword';

export const AuthPage = () => {
  useEffect(() => {
    document.body.classList.add('bg-white');
    return () => {
      document.body.classList.remove('bg-white');
    };
  }, []);

  return (
    <>
      <div
        className="auth-container d-flex flex-column flex-column-fluid bgi-position-y-bottom
      position-x-center bgi-no-repeat bgi-size-cover bgi-attachment-fixed"
        style={{
          backgroundImage: `url(${toAbsoluteUrl('/media/bg/auth-bg.png')})`,
        }}
      >
        {/* begin::Content */}
        <div className="top-bar position-absolute d-flex align-items-stretch justify-content-center
      justify-content-md-end flex-lg-grow-1 bg-transparent"
        >
          <div
            className="menu d-flex"
            data-kt-menu="true"
          >
            <Languages />
          </div>
        </div>
        <div className="d-flex flex-center flex-column flex-column-fluid p-10 pb-lg-20">
          {/* begin::Logo */}
          <a href="#" className="mb-12">
            <img alt="Logo" src={toAbsoluteUrl('/media/logos/Two-Wings-LOGO-250.png')} className="h-45px" />
          </a>
          {/* end::Logo */}
          {/* begin::Wrapper */}
          <div className="w-lg-500px bg-white rounded shadow-sm p-10 p-lg-15 mx-auto">
            <Switch>
              <Route path="/login" component={Login} />
              <Route path="/forgot-password" component={ForgotPassword} />
              <Route path="/change-password" component={ChangePassword} />
              <Redirect from="/auth" exact to="/login" />
              <Redirect to="/login" />
            </Switch>
          </div>
          {/* end::Wrapper */}
        </div>
        {/* end::Content */}
      </div>
    </>
  );
};
