import React, { useEffect } from 'react';
// Libraries
import { useDispatch } from 'react-redux';
import { Redirect, Switch } from 'react-router-dom';
// Redux
import * as auth from './redux/AuthRedux';

export const Logout = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    localStorage.setItem('refreshToken', '');
    localStorage.setItem('accessToken', '');
    dispatch(auth.actions.logout());
    document.location.reload();
  }, [dispatch]);

  return (
    <Switch>
      <Redirect to="/login" />
    </Switch>
  );
};
