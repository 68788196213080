import React from 'react';
// Libraries
import { Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';

export interface Props {
  size: number;
  className?: string;
}

const Loading: React.FC<Props> = ({ size, className }) => {
  const antIcon = (
    <LoadingOutlined style={{ color: '#efc940', fontSize: size }} spin />
  );

  return (
    <Spin
      className={className}
      indicator={antIcon}
    />
  );
};

Loading.defaultProps = {
  className: '',
};

export default Loading;
