import React, { useEffect, useState } from 'react';
// Libraries
import * as Yup from 'yup';
import clsx from 'clsx';
import { Link, useLocation, Redirect } from 'react-router-dom';
import { useFormik } from 'formik';
import queryString from 'query-string';
import { FormattedMessage, useIntl } from 'react-intl';
// i18n
import { useLang } from '_metronic/i18n/Metronici18n';
// Services
import AuthService from 'app/services/http/endpoints/auth';
// Components
import Button from 'app/components/Button';
import CustomLink from 'app/components/CustomLink';

const initialValues = {
  password: '',
  repPassword: '',
};

export const ChangePassword: React.FC = () => {
  // states
  const [loading, setLoading] = useState(false);
  const [isProcessingToken, setIsProcessingToken] = useState(true);
  const [isTokenValid, setIsTokenValid] = useState(false);
  const [isRequested, setIsRequested] = useState(false);

  // Hooks
  const { search } = useLocation();
  const intl = useIntl();
  const lang = useLang();

  const ForgotPasswordSchema = Yup.object().shape({
    password: Yup.string()
      .min(8, intl.formatMessage({ id: 'VALIDATION.MIN.FIELD' }, { number: 8 }))
      .required(intl.formatMessage({ id: 'VALIDATION.REQUIRED.FIELD' })),
    repPassword: Yup.string()
      .min(8, intl.formatMessage({ id: 'VALIDATION.MIN.FIELD' }, { number: 8 }))
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      .oneOf([Yup.ref('password'), null], intl.formatMessage({ id: 'VALIDATION.MATCH.FIELDS' }))
      .required(intl.formatMessage({ id: 'VALIDATION.REQUIRED.FIELD' })),
  });

  const formik: any = useFormik({
    initialValues,
    validationSchema: ForgotPasswordSchema,
    onSubmit: (values, { setStatus, setSubmitting }) => {
      setLoading(true);

      AuthService.putUpdatePassword({ password: values.password, repPassword: values.repPassword },
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        queryString.parse(search).token, lang)
        .then((res) => {
          setLoading(false);
          setIsRequested(true);
        })
        .catch((error) => {
          setLoading(false);
          setIsRequested(false);
          setSubmitting(false);

          if (error.response && error.response.data && error.response.data.password) {
            setStatus(error.response.data.password);
          } else {
            setStatus(intl.formatMessage({ id: 'SOME.ERRORS.DETECTED' }));
          }
        });
    },
  });

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    AuthService.getUpdatePassword(queryString.parse(search).token, lang)
      .then((res) => {
        setIsProcessingToken(false);
        setIsTokenValid(true);
      })
      .catch(() => {
        setIsProcessingToken(false);
      });
  }, []);

  return (
    isProcessingToken
      ? (
        <div>
          <FormattedMessage id="AUTH.CHANGE.PASSWORD.WE.PROCESSING.YOUR.TOKEN" />
        </div>
      )
      : (
        <>
          <div className="login-form login-forgot" style={{ display: 'block' }}>
            {isRequested && <Redirect to="/login" />}
            {!isTokenValid && (
            <div className="text-center">
              <div className="mb-6 font-size-lg font-weight-bold">
                <FormattedMessage id="AUTH.CHANGE.PASSWORD.TOKEN.IS.INVALID" />
              </div>
              <Link
                to="/forgot-password"
                className="blue_link font-size-lg text-hover-primary mr-2 mb-8"
                id="kt_login_forgot"
              >
                <FormattedMessage id="AUTH.CHANGE.PASSWORD.SEND.EMAIL.AGAIN" />
              </Link>
              <div className="mt-12">
                <Link to="/login">
                  <Button
                    type="button"
                    id="kt_login_forgot_cancel"
                    title="CANCEL"
                    isLight
                  />
                </Link>
              </div>
            </div>
            )}
            {isTokenValid && (
            <form
              className="form w-100 fv-plugins-bootstrap5 fv-plugins-framework"
              noValidate
              id="kt_login_password_reset_form"
              onSubmit={formik.handleSubmit}
            >
              {/* begin::Title */}
              {formik.status && (
              <div className="mb-lg-15 alert alert-danger">
                <div className="alert-text font-weight-bold">
                  {formik.status}
                </div>
              </div>
              )}
              {/* end::Title */}
              {/* begin::Form group */}
              <div className="fv-row mb-10">
                <div className="d-flex justify-content-between mt-n5">
                  <div className="d-flex flex-stack mb-2">
                    {/* begin::Label */}
                    {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
                    <label className="form-label fs-6 mb-0">
                      <FormattedMessage id="AUTH.CHANGE.PASSWORD.NEW.PASSWORD" />
                    </label>
                    {/* end::Label */}
                  </div>
                </div>
                <input
                  type="password"
                  autoComplete="off"
					// eslint-disable-next-line react/jsx-props-no-spreading
                  {...formik.getFieldProps('password')}
                  className={clsx(
                    'form-control form-control-lg form-control-solid',
                    {
                      'is-invalid': formik.touched.password && formik.errors.password,
                    },
                    {
                      'is-valid': formik.touched.password && !formik.errors.password,
                    },
                  )}
                />
                {formik.touched.password && formik.errors.password && (
                <div className="fv-plugins-message-container">
                  <div className="fv-help-block">
                    <span role="alert" className="text-danger">{formik.errors.password}</span>
                  </div>
                </div>
                )}
              </div>
              {/* end::Form group */}

              {/* begin::Form group */}
              <div className="fv-row mb-10">
                <div className="d-flex justify-content-between mt-n5">
                  <div className="d-flex flex-stack mb-2">
                    {/* begin::Label */}
                    {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
                    <label className="form-label fs-6 mb-0">
                      <FormattedMessage id="AUTH.CHANGE.PASSWORD.REPEAT.NEW.PASSWORD" />
                    </label>
                    {/* end::Label */}
                  </div>
                </div>
                <input
                  type="password"
                  autoComplete="off"
                  // eslint-disable-next-line react/jsx-props-no-spreading
                  {...formik.getFieldProps('repPassword')}
                  className={clsx(
                    'form-control form-control-lg form-control-solid',
                    {
                      'is-invalid': formik.touched.repPassword && formik.errors.repPassword,
                    },
                    {
                      'is-valid': formik.touched.repPassword && !formik.errors.repPassword,
                    },
                  )}
                />
                {formik.touched.repPassword && formik.errors.repPassword && (
                <div className="fv-plugins-message-container">
                  <div className="fv-help-block">
                    <span role="alert" className="text-danger">{formik.errors.repPassword}</span>
                  </div>
                </div>
                )}
              </div>
              {/* end::Form group */}

              {/* begin::Form group */}
              <div className="d-flex flex-wrap justify-content-center justify-content-sm-between pb-lg-0">
                <CustomLink
                  id="kt_login_password_reset_form_cancel_button"
                  to="/login"
                  className="mb-6"
                  title="CANCEL"
                  isLight
                />
                <Button
                  className="ms-sm-4 mb-6"
                  title="SAVE.CHANGES"
                  type="submit"
                  id="kt_password_reset_submit"
                  disabled={formik.isSubmitting || !formik.isValid || formik.values.password === ''
				  || formik.values.repPassword === ''}
                  loading={loading}
                />
              </div>
              {/* end::Form group */}
            </form>
            )}
          </div>
        </>
      )
  );
};
