import React from 'react';
import Loading from 'app/components/Loading';
//import {toAbsoluteUrl} from '../../../helpers'

export function FallbackView() {
  return (
    <div className='splash-screen'>
      <div className="h-100 loading-style">
        <Loading size={64} />
      </div>
    </div>
  );
}
