import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import clsx from 'clsx';
import React, { FC } from 'react';
import { Link } from 'react-router-dom';
import { useLayout } from '../../../core/LayoutProvider';
import { PageLink, usePageData } from '../../../core/PageData';

const DefaultTitle: FC = () => {
  const { pageTitle, pageDescription, pageBreadcrumbs } = usePageData();
  const { config, attributes, classes } = useLayout();

  const getBreadcrumbItem = (item: PageLink): JSX.Element => {
    if (!item.isSeparator) {
      if (item.isActive) {
        return (
          <Link className='breadcrumb-item text-hover-primary text-dark' to={item.path}>
            <span className='bg-black bullet bullet-dot ms-5'></span>
            <span className='ms-2'>{item.title}</span>
          </Link>
        );
      } else {
        return (
          <>
            <span className='bg-black bullet bullet-dot ms-5'></span>
            <span className='ms-2 text-dark'>{item.title}</span>
          </>
        );
      }
    } else {
      return (
        <>
          <span className='bg-black bullet bullet-dot ms-5'></span>
        </>
      );
    }
  };
  return (
    <div
      {...attributes.pageTitle}
      className={clsx('page-title d-flex mt-8 mt-sm-0', classes.pageTitle.join(' '))}
    >
      {/* begin::Title */}
      {pageTitle && (
        <h1 className='my-1 text-gray-700 d-flex align-items-center fw-bolder fs-3'>
          {pageTitle}
          {pageDescription && config.pageTitle && config.pageTitle.description && (
            <>
              <span className='mx-2 border-gray-200 h-20px border-start ms-3'></span>
              <small className='my-1 text-muted fs-7 fw-bold ms-1'>{pageDescription}</small>
            </>
          )}
        </h1>
      )}
      {/* end::Title */}

      {pageBreadcrumbs &&
        pageBreadcrumbs.length > 0 &&
        config.pageTitle &&
        config.pageTitle.breadCrumbs && (
          <>
            {config.pageTitle.direction === 'row' && <span className='mx-4 h-20px'></span>}
            <ul className='my-1 breadcrumb breadcrumb-separatorless fs-7'>
              <li key='dashboard' className='breadcrumb-item text-dark'>
                <Link className='breadcrumb-item text-hover-primary text-dark' to='/'>
                  <FontAwesomeIcon
                    color='#181C32'
                    className='cursor-pointer'
                    icon={['fal', 'tachometer-fast']}
                    size='lg'
                  />
                </Link>
              </li>
              {Array.from(pageBreadcrumbs).map((item, index) => (
                <li
                  className={clsx('breadcrumb-item', {
                    'text-dark': !item.isSeparator && item.isActive,
                    'text-muted': !item.isSeparator && !item.isActive,
                  })}
                  key={`${item.path}${index}`}
                >
                  {getBreadcrumbItem(item)}
                </li>
              ))}
              <li className='breadcrumb-item text-dark'>
                {pageBreadcrumbs[pageBreadcrumbs.length - 1].title
                  ? pageBreadcrumbs[pageBreadcrumbs.length - 1].title
                  : pageTitle}
              </li>
            </ul>
          </>
      )}
    </div>
  );
};

export { DefaultTitle };
