import React from 'react';
// Libraries
import { FormattedMessage } from 'react-intl';
import clsx from 'clsx';
import { Link } from 'react-router-dom';

interface Props {
  id?: string
  title: string
  to: string
  className?: string
  isLight?: boolean
  color?: 'orange' | 'navy-blue' | 'gray'
}

const CustomLink: React.FC<Props> = ({ id, isLight, color, className, to, title }) => {
  const chooseColor = (): string => {
    let result:string;
    switch (color) {
      case 'navy-blue':
        result = 'navy-btn';
        break;
      case 'gray':
        result = 'gray-btn';
        break;
      default:
        result = 'py-4';
    }
    return result;
  };
  return (
    <Link
      id={id}
      to={to}
      className={clsx(
        'btn font-weight-bold px-md-9 border-active',
        isLight ? 'btn-light-primary' : 'btn-primary',
        chooseColor(),
        className,
      )}
    >
      <FormattedMessage id={title} />
    </Link>
  );
};

CustomLink.defaultProps = {
  id: '',
  className: '',
  isLight: false,
  color: 'orange',
};

export default CustomLink;
