/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
// Libraries
import {
  Redirect, Route, Switch, Link,
} from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
// Helpers
import { toAbsoluteUrl } from '_metronic/helpers';
// Components
import { Languages } from '_metronic/partials/layout/header-menus/Languages';
import { Error500 } from './components/Error500';
import { Error404 } from './components/Error404';

const ErrorsPage: React.FC = () => (
  <div className="d-flex flex-column flex-root">
    <div
      className="error-container d-flex flex-column flex-column-fluid bgi-position-y-bottom position-x-center
        bgi-no-repeat bgi-size-contain bgi-attachment-fixed"
      style={{ backgroundImage: `url('${toAbsoluteUrl('/media/illustrations/progress-hd.png')}')` }}
    >
      <div className="top-bar position-absolute d-flex align-items-stretch justify-content-center
      justify-content-md-end flex-lg-grow-1 bg-transparent"
      >
        <div
          className="menu d-flex"
          data-kt-menu="true"
        >
          <Languages />
        </div>
      </div>
      <div className="d-flex flex-column flex-column-fluid text-center p-10 py-lg-20 mt-14 mt-sm-0">
        <Link to="/dashboard" className="mb-10 pt-lg-20">
          <img
            alt="Logo"
            src={toAbsoluteUrl('/media/logos/Two-Wings-LOGO-250.png')}
            className="h-50px mb-5"
          />
        </Link>
        <div className="pt-lg-10">
          <Switch>
            <Route path="/error/404" exact>
              <Error404 />
            </Route>
            <Route path="/error/500" exact>
              <Error500 />
            </Route>
            <Redirect from="/error" exact to="/error/404" />
            <Redirect to="/error/404" />
          </Switch>
          <div className="text-center">
            <Link to="/dashboard" className="btn btn-lg btn-primary fw-bolder">
              <FormattedMessage id="404.GO.TO.HOMEPAGE" />
            </Link>
          </div>
        </div>
      </div>
    </div>
  </div>
);

export { ErrorsPage };
