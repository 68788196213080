/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { FC } from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import { UserModel } from '../../../../app/modules/auth/models/UserModel';
import { RootState } from '../../../../setup';

const HeaderUserMenu: FC = () => {
  const user: UserModel = useSelector<RootState>(({ auth }) => auth.user, shallowEqual) as UserModel;
  return (
    <div
      // eslint-disable-next-line max-len
      className='py-4 menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg menu-state-primary fw-bold fs-6 w-275px'
      data-kt-menu='true'
    >
      <div className='px-5 menu-item'>
        <Link to='/logout' className='px-5 menu-link'>
          <FormattedMessage id='SIGN.OUT' />
        </Link>
      </div>
    </div>
  );
};

export { HeaderUserMenu };
