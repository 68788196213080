import React, { useEffect, useRef } from 'react';
// Libraries
import ApexCharts, { ApexOptions } from 'apexcharts';
// i18n
import { useLang } from '_metronic/i18n/Metronici18n';
// Utils
import { getCSSVariableValue } from '_metronic/assets/ts/_utils';
// Helpers
import  { toLocalAmount } from 'golf-uitilities';

type Props = {
  className: string;
  chartColor: string;
  chartHeight: string;
  labels: string[];
  data: string[];
  type: 'amount' | 'count';
};

const LineChart: React.FC<Props> = ({
  className,
  chartColor,
  chartHeight,
  data,
  labels,
  type,
}) => {
  // Hooks
  const lang = useLang();
  const chartRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    if (!chartRef.current) {
      return;
    }

    const chart = new ApexCharts(chartRef.current, chartOptions(chartColor, chartHeight, labels, data, type, lang));

    if (chart) {
      chart.render();
    }

    // eslint-disable-next-line consistent-return
    return () => {
      if (chart) {
        chart.destroy();
      }
    };
  }, [chartRef, data]);

  return (
    <div className={className}>
      <div ref={chartRef} className="mixed-widget-7-chart card-rounded-bottom" />
    </div>
  );
};

const chartOptions = (
  chartColor: string,
  chartHeight: string,
  labels: string[],
  data: string[],
  type: 'amount' | 'count',
  lang: string,
): ApexOptions => {
  const baseColor = getCSSVariableValue(`--bs-${chartColor}`);
  const lightColor = getCSSVariableValue(`--bs-light-${chartColor}`);

  return {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    series: [{ name: type, data }],
    chart: {
      fontFamily: 'inherit',
      type: 'area',
      height: chartHeight,
      toolbar: {
        show: false,
      },
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: 'smooth',
      show: true,
      width: 3,
      colors: [baseColor],
    },
    xaxis: {
      type: 'category',
      categories: labels,
    },
    yaxis: {
      labels: {
        formatter(value) {
          if (type === 'amount') {
            return toLocalAmount(value, lang);
          }
          return `${value}`;
        },
      },
    },
    colors: [baseColor],
    markers: {
      colors: [lightColor],
      strokeColors: [baseColor],
      strokeWidth: 3,
    },
  };
};

export default LineChart;
