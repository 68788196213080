/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable no-underscore-dangle */
/* eslint-disable implicit-arrow-linebreak */
// Libraries
import axios, { AxiosError } from 'axios';
// Types
import type { AxiosInstance } from 'axios';

/**
 * A HTTP service which created by Axios instance creator
 *
 * @abstract
 */

abstract class BaseAPI {
  protected httpService: AxiosInstance;

  private refreshService : AxiosInstance;

  protected constructor(group = '', timeout = 50000, baseURL = '/api/') {
    axios.defaults.headers = {
      Accept: 'application/json; case-transformation=camel',
    };

    this.httpService = axios.create({
      baseURL: `${process.env.REACT_APP_API_URL}${baseURL}${group}`,
      timeout,
    });

    this.refreshService = axios.create({
      baseURL: `${process.env.REACT_APP_API_URL}/api/token/refresh/`,
      timeout: 10000,
    });

    this.responseInterceptors();
  }

  private responseInterceptors() {
    this.httpService.interceptors.response.use(
      (response) => response,
      async (error: AxiosError) => {
        const originalRequest = error.config;
        if (error?.response?.status === 401 && localStorage.getItem('refreshToken') !== '') {
          localStorage.setItem('accessToken', '');
          const accessToken = await this.requestNewAccessToken();
          originalRequest.headers = {
            Accept: 'application/json; case-transformation=camel',
            Authorization: `Bearer ${accessToken}`,
          };
          return this.httpService(originalRequest);
        }
        return Promise.reject(error);
      },
    );
  }

  private async requestNewAccessToken() {
    const refreshToken = localStorage.getItem('refreshToken');
    let accessToken = '';
    await this.refreshService(
      {
        method: 'post',
        data: {
          refresh: refreshToken,
        },
      },
    ).then((response) => {
      axios.defaults.headers.Authorization = `Bearer ${response.data.refresh}`;
      accessToken = response.data.access;
      localStorage.setItem('refreshToken', response.data.refresh);
      localStorage.setItem('accessToken', response.data.access);
    })
      .catch((error) => {
        localStorage.setItem('refreshToken', '');
        localStorage.setItem('accessToken', '');
        window.location.href = '/logout';
        // eslint-disable-next-line no-console
        console.log('function*base -> error', JSON.stringify(error));
      });
    return accessToken;
  }
}

export { BaseAPI };
