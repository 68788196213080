import React, { Suspense, lazy } from 'react';
// Libraries
import { Redirect, Route, Switch } from 'react-router-dom';
// Components
import { FallbackView } from '_metronic/partials';
import { DashboardWrapper } from 'app/pages/dashboard/DashboardWrapper';

export const PrivateRoutes = () => {
  const ProgramPage = lazy(() => import('../modules/program/ProgramPage'));
  const DonationPage = lazy(() => import('../modules/donation/internal/InternalDonationPage'));
  const ExternalDonationPage = lazy(() => import('../modules/donation/external/ExternalDonationPage'));
  const DonorPage = lazy(() => import('../modules/donor/DonorPage'));
  const SubscriberPage = lazy(() => import('../modules/newsletter/subscriber/SubscriberPage'));
  const UnsubscriberPage = lazy(() => import('../modules/newsletter/unsubscriber/UnsubscriberPage'));
  const MediaPage = lazy(() => import('../modules/media/MediaPage'));
  const VacancyPage = lazy(() => import('../modules/vacancies/vacancyList/VacancyListPage'));
  const ApplicationPage = lazy(() => import('../modules/vacancies/applications/ApplicationListPage'));
  const VacancySettingPage = lazy(() => import('../modules/vacancies/settings/VacancySettingPage'));
  const FAQListPage = lazy(() => import('../modules/faq/faqList/FAQListPage'));
  const FAQSettingPage = lazy(() => import('../modules/faq/settings/FAQSettingPage'));
  const ContactMsgListPage = lazy(() => import('../modules/contact/contactList/ContactMsgListPage'));
  const InsightDocumentListPage = lazy(() => import('../modules/insight/documentList/DocumentListPage'));
  const InsightArticleListPage = lazy(() => import('../modules/insight/article/ArticleListPage'));


  return (
    <Suspense fallback={<FallbackView />}>
      <Switch>
        <Route path="/dashboard" component={DashboardWrapper} />
        <Route path="/program" component={ProgramPage} />
        <Route path="/donation/internal" component={DonationPage} />
        <Route path="/donation/external" component={ExternalDonationPage} />
        <Route path="/donor" component={DonorPage} />
        <Route path="/newsletters/subscriber-list" component={SubscriberPage} />
        <Route path="/newsletters/unsubscriber-list" component={UnsubscriberPage} />
        <Route path="/gallery" component={MediaPage} />
        <Route path="/vacancies/vacancy-list" component={VacancyPage} />
        <Route path="/vacancies/vacancy-applications" component={ApplicationPage} />
        <Route path="/vacancies/vacancy-settings" component={VacancySettingPage} />
        <Route path="/faq/faq-list" component={FAQListPage} />
        <Route path="/faq/faq-settings" component={FAQSettingPage} />
        <Route path="/contact" component={ContactMsgListPage} />
        <Route path="/insight/document-list" component={InsightDocumentListPage} />
        <Route path="/insight/article-list" component={InsightArticleListPage} />
        <Redirect from="/login" to="/dashboard" />
        <Redirect exact from="/" to="/dashboard" />
        <Redirect to="error/404" />
      </Switch>
    </Suspense>
  );
};
