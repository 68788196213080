import React, {
  FC, useRef, useEffect, useState,
} from 'react';
// Libraries
import { connect, useDispatch, ConnectedProps } from 'react-redux';
// i18n
import { useLang } from '_metronic/i18n/Metronici18n';
// Services
import UserService from 'app/services/http/endpoints/user';
// Components
import { LayoutSplashScreen } from '_metronic/layout/core';
// Redux
import { RootState } from 'setup';
import * as auth from './AuthRedux';

const mapState = (state: RootState) => ({ auth: state.auth });
const connector = connect(mapState, auth.actions);
type PropsFromRedux = ConnectedProps<typeof connector>;

const AuthInit: FC<PropsFromRedux> = (props) => {
  // Hooks
  const didRequest = useRef(false);
  const dispatch = useDispatch();
  const [showSplashScreen, setShowSplashScreen] = useState(true);
  const lang = useLang();

  const accessToken = localStorage.getItem('refreshToken');

  // We should request user by authToken before rendering the application
  useEffect(() => {
    const requestUser = async () => {
      try {
        if (!didRequest.current) {
          const { data: user } = await UserService.getUser(lang);
          dispatch(props.fulfillUser(user));
        }
      } catch (error) {
        if (!didRequest.current) {
          dispatch(props.logout());
        }
      } finally {
        setShowSplashScreen(false);
      }

      return () => (didRequest.current = true);
    };

    if (accessToken) {
      requestUser();
    } else {
      dispatch(props.logout());
      setShowSplashScreen(false);
    }
  }, []);

  return showSplashScreen ? <LayoutSplashScreen /> : <>{props.children}</>;
};

export default connector(AuthInit);
