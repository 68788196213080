import React, { FC, useEffect, useState } from 'react';
// Libraries
import { useIntl } from 'react-intl';
// i18n
import { useLang } from '_metronic/i18n/Metronici18n';
// Models
import { AxiosResponse } from 'axios';
import { DashboardCardModel } from 'app/pages/dashboard/models/DashboardCardModel';
import { ChartInfoModel } from 'app/pages/dashboard/models/ChartInfoModel';
import { DashboardInfoModel } from 'app/pages/dashboard/models/DashboardInfoModel';
// Services
import DashboardService from 'app/services/http/endpoints/dashboard';
// Helpers
import  { toLocalAmount } from 'golf-uitilities';
// Components
import { PageTitle } from '_metronic/layout/core';
import DashboardInfo from 'app/pages/dashboard/components/DashboardInfo';
import DashboardCard from 'app/pages/dashboard/components/DashboardCard';
import Loading from 'app/components/Loading';
import { DashboardActionsBar } from './components/DashboardActionBar';
import { showSomethingWrong } from 'app/helpers/notificationHelper';

const DashboardPage: FC = () => {
  // States
  const [dashboardInfo, setDashboardInfo] = useState<DashboardCardModel[]>();
  const [pieChartInfo, setPieChartInfo] = useState<ChartInfoModel>();
  const [lineChartInfo, setLineChartInfo] = useState<ChartInfoModel>();

  // Hooks
  const lang = useLang();
  const intl = useIntl();

  const getDashboardChartsInfo = (selectedYear: number) => {
	  DashboardService.getDashboardInfo(selectedYear, lang)
		  .then((res: AxiosResponse<DashboardInfoModel>) => {
			  const infos = res.data;

			  if (infos) {
				  setDashboardInfo([
					  {
						  title: intl.formatMessage({ id: 'DASHBOARD.TOTAL.SUBSCRIBER' }),
						  value: infos.totalSubscribers,
						  color: 'text-warning',
					  },
					  {
						  title: intl.formatMessage({ id: 'DASHBOARD.RECEIVED.DONATIONS' }),
						  value: infos.receivedDonations,
						  color: 'text-info',
					  },
					  {
						  title: intl.formatMessage({ id: 'DASHBOARD.TOTAL.AMOUNT' }),
						  value: toLocalAmount(infos.totalDonationAmount, lang),
						  color: 'text-danger',
					  },
				  ]);
			  }
		  })
		  .catch(() => {
			  showSomethingWrong(intl);
		  });

	  DashboardService.getPieChartInfo(selectedYear, lang)
		  .then((res: AxiosResponse<ChartInfoModel>) => { setPieChartInfo(res.data); })
		  .catch(() => {
			  showSomethingWrong(intl);
		  });

	  DashboardService.getLineChartInfo(selectedYear, lang)
		  .then((res: AxiosResponse<ChartInfoModel>) => { setLineChartInfo(res.data); })
		  .catch(() => {
			  showSomethingWrong(intl);
		  });
  };

  useEffect(() => {
    getDashboardChartsInfo((new Date()).getFullYear());
  }, []);

  return (
    <div className="dashboard-page">
		<DashboardActionsBar handlegetChartsInfo={getDashboardChartsInfo} />
      {dashboardInfo && pieChartInfo && lineChartInfo ? (
        <div className="text-center row gy-5 g-xl-8">
          <div className="col-xl-3">
            <DashboardInfo
              title={intl.formatMessage({ id: 'DASHBOARD.RECEIVED.DONATIONS' })}
              className="card-xl-stretch mb-xl-8"
              chartColor="navy-blue"
              chartHeight="200px"
              strokeColor="#ffffff"
              infos={dashboardInfo}
            />
          </div>
          <div className="col-xl-9">
            <div className="row">
              <div className="mb-5 col-xl-6 mb-xl-0">
                <DashboardCard
                  title={intl.formatMessage({ id: 'DASHBOARD.COUNT' })}
                  type="count"
                  pieChartInfo={pieChartInfo}
                  pieChartColors={['#DCE6FF', '#ADE2ED', '#FFE796']}
                  lineChartInfo={lineChartInfo}
                  lineChartColor="primary"
                />
              </div>
              <div className="col-xl-6">
                <DashboardCard
                  title={intl.formatMessage({ id: 'DASHBOARD.AMOUNT' })}
                  type="amount"
                  pieChartInfo={pieChartInfo}
                  pieChartColors={['#FFCE90', '#FA93BB', '#9FA7EF']}
                  lineChartInfo={lineChartInfo}
                  lineChartColor="warning"
                />
              </div>
            </div>
          </div>
        </div>
      )
		  : (
  <div className="d-flex justify-content-center align-items-center h-100">
    <Loading size={64} />
  </div>
        )}
    </div>
  );
};

const DashboardWrapper: FC = () => {
  // Hooks
  const intl = useIntl();

  return (
    <>
      <PageTitle breadcrumbs={[]}>{intl.formatMessage({ id: 'MENU.DASHBOARD' })}</PageTitle>
      <DashboardPage />
    </>
  );
};

export { DashboardWrapper };
