import React, { Suspense } from 'react';
// Libraries
import { BrowserRouter } from 'react-router-dom';
import { ConfigProvider } from 'antd';
import deDE from 'antd/lib/locale/de_DE';
import enUS from 'antd/lib/locale/en_US';
// i18n
import { useLang } from '_metronic/i18n/Metronici18n';
// Constants
import { DEFAULT_LANGUAGE } from './constants/generals';
// Components
import { I18nProvider } from '_metronic/i18n/i18nProvider';
import { LayoutProvider, LayoutSplashScreen } from '_metronic/layout/core';
import AuthInit from 'app/modules/auth/redux/AuthInit';
import { Routes } from 'app/routing/Routes';
import 'antd/dist/antd.css';

type Props = {
  basename: string
};

const App: React.FC<Props> = ({ basename }) => {

  // Hooks
  const lang = useLang();

  return (
  <Suspense fallback={<LayoutSplashScreen />}>
    <BrowserRouter basename={basename}>
      <I18nProvider>
		  <ConfigProvider locale={lang === DEFAULT_LANGUAGE ? deDE : enUS}>
		  <LayoutProvider>
          <AuthInit>
            <Routes />
          </AuthInit>
        </LayoutProvider>
		  </ConfigProvider>
      </I18nProvider>
    </BrowserRouter>
  </Suspense>
  );
};

export { App };
