/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useRef } from 'react';
// Libraries
import ApexCharts, { ApexOptions } from 'apexcharts';
import clsx from 'clsx';
// Utils
import { getCSSVariableValue } from '_metronic/assets/ts/_utils';
// Models
import { DashboardCardModel } from 'app/pages/dashboard/models/DashboardCardModel';

type Props = {
  className: string;
  chartColor: string;
  strokeColor: string;
  chartHeight: string;
  infos?: DashboardCardModel[];
  title?: string;
};

const DashboardInfo: React.FC<Props> = ({
  className,
  chartColor,
  chartHeight,
  strokeColor,
  infos,
  title,
}) => {
  // Hooks
  const chartRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    if (!chartRef.current) {
      return;
    }

    const chart = new ApexCharts(
      chartRef.current,
      chartOptions(chartHeight, chartColor, strokeColor),
    );
    if (chart) {
      chart.render();
    }

    // eslint-disable-next-line consistent-return
    return () => {
      if (chart) {
        chart.destroy();
      }
    };
  }, [chartRef]);

  return (
    <div className={`dashboard-info card ${className}`}>
      {/* begin::Header */}
      <div className={`card-header justify-content-center border-0 py-5 bg-${chartColor}`}>
        <h3 className="text-white card-title fw-bolder">{title}</h3>
      </div>
      {/* end::Header */}
      {/* begin::Body */}
      <div className="p-0 card-body">
        {/* begin::Chart */}
        <div
          ref={chartRef}
          className={`mixed-widget-2-chart card-rounded-bottom bg-${chartColor}`}
        />
        {/* end::Chart */}
        {/* begin::Stats */}
        {infos && infos.map((info: {
          color: string,
          title: string, value: string }) => (
            <div key={info.title} className="card-p mt-n20 position-relative">
              {/* begin::Row */}
              <div className="row g-0">
                {/* begin::Col */}
                <div className={clsx('col bg-white py-8 mb-7 rounded-3 text-center')}>
                  <a href="#" className={clsx('fw-bold fs-6', info.color)}>
                    {info.title}
                  </a>
                  <div className={clsx('text-center mt-2', info.color)}>{info.value}</div>
                </div>
                {/* end::Col */}
              </div>
              {/* end::Row */}
            </div>
        ))}
        {/* end::Stats */}
      </div>
      {/* end::Body */}
    </div>
  );
};

DashboardInfo.defaultProps = {
  title: '',
  infos: [],
};

const chartOptions = (
  chartHeight: string,
  chartColor: string,
  strokeColor: string,
): ApexOptions => {
  const labelColor = getCSSVariableValue('--bs-gray-500');
  const borderColor = getCSSVariableValue('--bs-gray-200');
  const color = getCSSVariableValue(`--bs-${chartColor}`);

  return {
    series: [
      {
        name: 'Net Profit',
        data: [30, 45, 32, 70, 40, 40, 40],
      },
    ],
    chart: {
      fontFamily: 'inherit',
      type: 'area',
      height: chartHeight,
      toolbar: {
        show: false,
      },
      zoom: {
        enabled: false,
      },
      sparkline: {
        enabled: true,
      },
      dropShadow: {
        enabled: true,
        enabledOnSeries: undefined,
        top: 5,
        left: 0,
        blur: 3,
        color: strokeColor,
        opacity: 0.5,
      },
    },
	  tooltip: {
		  enabled: false,
	  },
    plotOptions: {},
    legend: {
      show: false,
    },
    dataLabels: {
      enabled: false,
    },
    fill: {
      type: 'solid',
      opacity: 0,
    },
    stroke: {
      curve: 'smooth',
      show: true,
      width: 3,
      colors: [strokeColor],
    },
    xaxis: {
      categories: ['Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug'],
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
      labels: {
        show: false,
        style: {
          colors: labelColor,
          fontSize: '12px',
        },
      },
      crosshairs: {
        show: false,
        position: 'front',
        stroke: {
          color: borderColor,
          width: 1,
          dashArray: 3,
        },
      },
    },
    yaxis: {
      min: 0,
      max: 80,
      labels: {
        show: false,
        style: {
          colors: labelColor,
          fontSize: '12px',
        },
      },
    },
    states: {
      normal: {
        filter: {
          type: 'none',
          value: 0,
        },
      },
      hover: {
        filter: {
          type: 'none',
          value: 0,
        },
      },
      active: {
        allowMultipleDataPointsSelection: false,
        filter: {
          type: 'none',
          value: 0,
        },
      },
    },
    colors: ['transparent'],
    markers: {
      colors: [color],
      strokeColors: [strokeColor],
      strokeWidth: 3,
    },
  };
};

export default DashboardInfo;
