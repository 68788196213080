/* eslint-disable @typescript-eslint/no-explicit-any */
// Types
import { AxiosResponse } from 'axios';
// Utilities
import { BaseAPI } from '../base';
// Models
import { ChartInfoModel } from '../../../pages/dashboard/models/ChartInfoModel';
import { DashboardInfoModel } from '../../../pages/dashboard/models/DashboardInfoModel';

class DashboardProvider extends BaseAPI {
  public constructor() {
    super('admin/dashboard/donation/');
  }

  public getDashboardInfo(selectedYear: number, lang = 'de'): Promise<AxiosResponse<DashboardInfoModel>> {
    const authToken = localStorage.getItem('accessToken');

    return this.httpService.get(`info/${selectedYear}`, {
      headers: {
        'Accept-Language': lang,
        Authorization: `Bearer ${authToken}`,
      },
    });
  }

  public getPieChartInfo(selectedYear: number, lang = 'en'): Promise<AxiosResponse<ChartInfoModel>> {
    const authToken = localStorage.getItem('accessToken');

    return this.httpService.get(`chart/pie/${selectedYear}`, {
      headers: {
        'Accept-Language': lang,
        Authorization: `Bearer ${authToken}`,
      },
    });
  }

  public getLineChartInfo(selectedYear: number, lang = 'en'): Promise<AxiosResponse<ChartInfoModel>> {
    const authToken = localStorage.getItem('accessToken');

    return this.httpService.get(`chart/line/${selectedYear}`, {
      headers: {
        'Accept-Language': lang,
        Authorization: `Bearer ${authToken}`,
      },
    });
  }
}

const DashboardService = new DashboardProvider();

export default DashboardService;
