// Types
import { AxiosResponse } from 'axios';
// Utilities
import { BaseAPI } from '../base';

class AuthProvider extends BaseAPI {
  public constructor() {
    super();
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public login(data: any, lang = 'de'): Promise<AxiosResponse<any>> {
    return this.httpService.post('token/', data, {
      headers: {
        'Accept-Language': lang,
      },
    });
  }

  public resetPassword(data: any, lang = 'de'): Promise<AxiosResponse<any>> {
    return this.httpService.post('reset_password/', data, {
      headers: {
        'Accept-Language': lang,
      },
    });
  }

  public getUpdatePassword(tokenKey: string, lang = 'de'): Promise<AxiosResponse<any>> {
    return this.httpService.get(`update_password/${tokenKey}/`, {
      headers: {
        'Accept-Language': lang,
      },
    });
  }

  public putUpdatePassword(data: any, tokenKey: string, lang = 'en'): Promise<AxiosResponse<any>> {
    return this.httpService.put(`update_password/${tokenKey}/`, data, {
      headers: {
        'Accept-Language': lang,
      },
    });
  }
}

const AuthService = new AuthProvider();

export default AuthService;
