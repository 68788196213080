import React from 'react';
// Libraries
import { FormattedMessage } from 'react-intl';
import clsx from 'clsx';

interface Props {
  id?: string
  title: string
  type?: 'submit' | 'button'
  disabled?: boolean
  className?: string
  loading?: boolean
  isLight?: boolean
  color?: 'orange' | 'navy-blue' | 'red' | 'gray' | 'green'
  onClick?: () => void
}

const Button: React.FC<Props> = ({
  id,
  type,
  disabled,
  isLight,
  color,
  className,
  loading,
  title,
  onClick,
}) => {
  const chooseColor = (): string => {
    let result:string;
    switch (color) {
      case 'navy-blue':
        result = 'navy-btn';
        break;
      case 'red':
        result = 'red-btn';
        break;
      case 'green':
        result = 'green-btn';
        break;
      case 'gray':
        result = 'gray-btn';
        break;
      default:
        result = 'py-4';
    }
    return result;
  };
  return (
    <button
      id={id}
      // eslint-disable-next-line react/button-has-type
      type={type}
      disabled={disabled}
      className={clsx(
        'btn font-weight-bold px-md-6 border-active',
        isLight ? 'btn-light-primary' : 'btn-primary',
        chooseColor(),
        className,
      )}
      onClick={onClick}
    >
      {!loading && (
        <span className='indicator-label'>
          <FormattedMessage id={title} />
        </span>
      )}
      {loading && (
        <span className='indicator-progress' style={{ display: 'block' }}>
          <FormattedMessage id='PLEASE.WAIT' />
          <span className='align-middle spinner-border spinner-border-sm ms-2' />
        </span>
      )}
    </button>
  );
};

Button.defaultProps = {
  id: '',
  type: 'submit',
  disabled: false,
  className: '',
  loading: false,
  isLight: false,
  color: 'orange',
  onClick: undefined,
};

export default Button;
