import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { useIntl } from 'react-intl';
import { AsideMenuItem } from './AsideMenuItem';
import { AsideMenuItemWithSub } from './AsideMenuItemWithSub';

export function AsideMenuMain() {
  // Hooks
  const intl = useIntl();

  return (
    <>
      <AsideMenuItem
        to='/dashboard'
        icon='/media/svg/menus/DashboardIcon.svg'
        title={intl.formatMessage({ id: 'MENU.DASHBOARD' })}
        fontIcon='bi-app-indicator'
      />
      <AsideMenuItem
        to='/program'
        icon='/media/svg/menus/ProgramsIcon.svg'
        title={intl.formatMessage({ id: 'MENU.PROGRAMS' })}
        fontIcon='bi-app-indicator'
      />
      <AsideMenuItemWithSub
        to='/donation'
        icon='/media/svg/menus/DonationIcon.svg'
        title={intl.formatMessage({ id: 'MENU.DONATIONS' })}
        fontIcon='bi-app-indicator'
      >
        <AsideMenuItem
          to='/donation/internal'
          icon=''
          title={intl.formatMessage({ id: 'MENU.INTERNAL' })}
          fontIcon=''
          hasBullet
          isSubMenu
        />
        <AsideMenuItem
          to='/donation/external'
          icon=''
          title={intl.formatMessage({ id: 'MENU.EXTERNAL' })}
          fontIcon=''
          hasBullet
          isSubMenu
        />
      </AsideMenuItemWithSub>
      <AsideMenuItem
        icon='/media/svg/menus/DonorsIcon.svg'
        to='/donor'
        title={intl.formatMessage({ id: 'MENU.DONORS' })}
        fontIcon='bi-app-indicator'
      />
      <AsideMenuItemWithSub
        to='/newsletters'
        FontAwsomeIcon={<FontAwesomeIcon size='lg' icon={['fal', 'mailbox']} />}
        title={intl.formatMessage({ id: 'MENU.NEWSLETTERS' })}
        fontIcon='bi-app-indicator'
      >
        <AsideMenuItem
          to='/newsletters/subscriber-list'
          icon=''
          title={intl.formatMessage({ id: 'MENU.SUBSCRIBERS' })}
          fontIcon=''
          hasBullet
          isSubMenu
        />
        <AsideMenuItem
          to='/newsletters/unsubscriber-list'
          icon=''
          title={intl.formatMessage({ id: 'MENU.UNSUBSCRIBERS' })}
          fontIcon=''
          hasBullet
          isSubMenu
        />
      </AsideMenuItemWithSub>
      <AsideMenuItemWithSub
        to='/vacancies'
        title={intl.formatMessage({ id: 'MENU.VACANCIES' })}
        fontIcon='bi-app-indicator'
        FontAwsomeIcon={<FontAwesomeIcon size='lg' icon={['fal', 'clipboard-list-check']} />}
      >
        <AsideMenuItem
          to='/vacancies/vacancy-list'
          icon=''
          title={intl.formatMessage({ id: 'MENU.VACANCY.LIST' })}
          fontIcon=''
          hasBullet
          isSubMenu
        />
        <AsideMenuItem
          to='/vacancies/vacancy-applications'
          icon=''
          title={intl.formatMessage({ id: 'MENU.APPLICATION.LIST' })}
          fontIcon=''
          hasBullet
          isSubMenu
        />
        <AsideMenuItem
          to='/vacancies/vacancy-settings'
          icon=''
          title={intl.formatMessage({ id: 'MENU.VACANCY.SETTINGS' })}
          fontIcon=''
          hasBullet
          isSubMenu
        />
      </AsideMenuItemWithSub>
      <AsideMenuItemWithSub
        to='/faq'
        title={intl.formatMessage({ id: 'MENU.FAQ' })}
        fontIcon='bi-app-indicator'
        FontAwsomeIcon={<FontAwesomeIcon size='lg' icon={['fal', 'question-circle']} />}
      >
        <AsideMenuItem
          to='/faq/faq-list'
          icon=''
          title={intl.formatMessage({ id: 'MENU.FAQ.LIST' })}
          fontIcon=''
          hasBullet
          isSubMenu
        />

        <AsideMenuItem
          to='/faq/faq-settings'
          icon=''
          title={intl.formatMessage({ id: 'MENU.FAQ.SETTINGS' })}
          fontIcon=''
          hasBullet
          isSubMenu
        />
      </AsideMenuItemWithSub>
      <AsideMenuItem
        FontAwsomeIcon={<FontAwesomeIcon size='lg' icon={['fal', 'envelope']} />}
        to='/contact'
        title={intl.formatMessage({ id: 'MENU.CONTACT.MESSAGES' })}
        fontIcon='bi-app-indicator'
        icon='svg'
      />
      <AsideMenuItemWithSub
        to='/insight'
        title={intl.formatMessage({ id: 'MENU.INSIGHT' })}
        fontIcon='bi-app-indicator'
        FontAwsomeIcon={<FontAwesomeIcon size='lg' icon={['fal', 'pen-square']} />}
      >
        <AsideMenuItem
          to='/insight/article-list'
          icon=''
          title={intl.formatMessage({ id: 'MENU.INSIGHT.ARTICLE.LIST' })}
          fontIcon=''
          hasBullet
          isSubMenu
        />
        <AsideMenuItem
          to='/insight/document-list'
          icon=''
          title={intl.formatMessage({ id: 'MENU.INSIGHT.DOCUMENT.LIST' })}
          fontIcon=''
          hasBullet
          isSubMenu
        />
      </AsideMenuItemWithSub>

      <AsideMenuItem
        FontAwsomeIcon={<FontAwesomeIcon size='lg' icon={['far', 'image']} />}
        to='/gallery/gallery-list'
        title={intl.formatMessage({ id: 'MENU.MEDIA' })}
        fontIcon='bi-app-indicator'
        icon='svg'
      />
    </>
  );
}
