/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from 'react';
// Libraries
import { useDispatch } from 'react-redux';
import * as Yup from 'yup';
import clsx from 'clsx';
import { Link } from 'react-router-dom';
import { useFormik } from 'formik';
import { FormattedMessage, useIntl } from 'react-intl';
// i18n
import { useLang } from '_metronic/i18n/Metronici18n';
// Types
import { AxiosError } from 'axios';
// Services
import AuthService from 'app/services/http/endpoints/auth';
// Components
import Button from 'app/components/Button';
// Redux
import * as auth from '../redux/AuthRedux';

const initialValues = {
  email: '',
  password: '',
};

/*
  Formik+YUP+Typescript:
  https://jaredpalmer.com/formik/docs/tutorial#getfieldprops
  https://medium.com/@maurice.de.beijer/yup-validation-and-typescript-and-formik-6c342578a20e
*/

export const Login: React.FC = () => {
  // States
  const [loading, setLoading] = useState(false);

  // Hooks
  const intl = useIntl();
  const lang = useLang();
  const dispatch = useDispatch();

  const loginSchema = Yup.object().shape({
    email: Yup.string()
      .email(intl.formatMessage({ id: 'VALIDATION.EMAIL.FIELD' }))
      .min(3, intl.formatMessage({ id: 'VALIDATION.MIN.FIELD' }, { number: 3 }))
      .required(intl.formatMessage({ id: 'VALIDATION.REQUIRED.FIELD' })),
    password: Yup.string()
      .min(3, intl.formatMessage({ id: 'VALIDATION.MIN.FIELD' }, { number: 3 }))
      .required(intl.formatMessage({ id: 'VALIDATION.REQUIRED.FIELD' })),
  });

  const formik: any = useFormik({
    initialValues,
    validationSchema: loginSchema,
    onSubmit: (values, { setStatus, setSubmitting }) => {
      setLoading(true);
      setTimeout(() => {
        AuthService.login({ email: values.email, password: values.password }, lang)
          .then((res) => {
            if (res.data) {
              setLoading(false);
              localStorage.setItem('refreshToken', res.data.refresh);
              localStorage.setItem('accessToken', res.data.access);
              dispatch(auth.actions.login());
            }
          }).catch((error: AxiosError) => {
            setStatus(intl.formatMessage({ id: 'AUTH.VALIDATION.INVALID_LOGIN' }));
          })
          .finally(() => {
            setLoading(false);
            setSubmitting(false);
          });
      }, 1000);
    },
  });

  return (
    <div className="login-form login-signin">
      {/* begin::Form */}
      <form
        className="form w-100"
        onSubmit={formik.handleSubmit}
        noValidate
        id="kt_login_signin_form"
      >

        {formik.status
        && (
        <div className="mb-lg-15 alert alert-danger">
          <div className="alert-text font-weight-bold">{formik.status}</div>
        </div>
        )}

        {/* begin::Form group */}
        <div className="mb-10 fv-row">
          {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
          <label className="form-label fs-6">
            <FormattedMessage id="AUTH.GENERAL.EMAIL" />
          </label>
          <input
            placeholder={intl.formatMessage({ id: 'AUTH.GENERAL.EMAIL' })}
			// eslint-disable-next-line react/jsx-props-no-spreading
            {...formik.getFieldProps('email')}
            className={clsx(
              'form-control form-control-lg form-control-solid',
              { 'is-invalid': formik.touched.email && formik.errors.email },
              {
                'is-valid': formik.touched.email && !formik.errors.email,
              },
            )}
            type="email"
            name="email"
            autoComplete="off"
          />
          {formik.touched.email && formik.errors.email && (
          <div className="fv-plugins-message-container">
            <span role="alert" className="text-danger">{formik.errors.email}</span>
          </div>
          )}
        </div>
        {/* end::Form group */}

        {/* begin::Form group */}
        <div className="mb-10 fv-row">
          <div className="d-flex justify-content-between mt-n5">
            <div className="mb-2 d-flex flex-stack">
              {/* begin::Label */}
              {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
              <label className="mb-0 form-label fs-6">
                <FormattedMessage id="AUTH.GENERAL.PASSWORD" />
              </label>
              {/* end::Label */}
            </div>
          </div>
          <input
            type="password"
            placeholder={intl.formatMessage({ id: 'AUTH.GENERAL.PASSWORD' })}
            autoComplete="off"
			      // eslint-disable-next-line react/jsx-props-no-spreading
            {...formik.getFieldProps('password')}
            className={clsx(
              'form-control form-control-lg form-control-solid',
              {
                'is-invalid': formik.touched.password && formik.errors.password,
              },
              {
                'is-valid': formik.touched.password && !formik.errors.password,
              },
            )}
          />
          {formik.touched.password && formik.errors.password && (
          <div className="fv-plugins-message-container">
            <div className="fv-help-block">
              <span role="alert" className="text-danger">{formik.errors.password}</span>
            </div>
          </div>
          )}
        </div>
        {/* end::Form group */}

        {/* begin::Action */}
        <div className="mt-4 mb-6 text-center">
          <Button
            title="AUTH.LOGIN.BUTTON"
            type="submit"
            id="kt_sign_in_submit"
            className="mb-5"
            disabled={formik.isSubmitting || !formik.isValid || formik.values.email === ''
			|| formik.values.password === ''}
            loading={loading}
          />
        </div>
        {/* end::Action */}

        {/* begin::Form group */}
        <div className="mb-10 text-center fv-row">
          {/* begin::Link */}
          <Link
            to="/forgot-password"
            className="text-black-50 fs-6 fw-bolder"
            style={{ marginLeft: '5px' }}
          >
            <FormattedMessage id="AUTH.GENERAL.FORGOT_BUTTON" />
          </Link>
          {/* end::Link */}
        </div>
        {/* end::Form group */}
      </form>
      {/* end::Form */}
    </div>
  );
};

