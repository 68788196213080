import React from 'react';
import { AsideDefault } from './components/aside/AsideDefault';
import { Footer } from './components/Footer';
import { HeaderWrapper } from './components/header/HeaderWrapper';
import { Toolbar } from './components/toolbar/Toolbar';
import { ScrollTop } from './components/ScrollTop';
import { Content } from './components/Content';
import { MasterInit } from './MasterInit';
import { PageDataProvider } from './core';
import {
  DrawerMessenger,
  ExploreMain,
  ActivityDrawer,
  Main,
  InviteUsers,
  UpgradePlan,
} from '../partials';
import { toAbsoluteUrl } from '../helpers';

const MasterLayout: React.FC = ({ children }) => {
  return (
    <PageDataProvider>
      <div className='flex-row page d-flex flex-column-fluid'>
        <AsideDefault />
        <div
          className=
          // eslint-disable-next-line max-len
          'wrapper d-flex flex-column flex-row-fluid bgi-position-x-end position-x-center bgi-no-repeat bgi-attachment-fixed'
          id='kt_wrapper'
          style={{ backgroundImage: `url(${toAbsoluteUrl('/media/bg/dashboard-bg.png')})` }}
        >
          <HeaderWrapper />

          <div id='kt_content' className='content d-flex flex-column flex-column-fluid'>
            <div>
              <Toolbar />
            </div>
            <div className='post d-flex flex-column-fluid' id='kt_post'>
              <Content>{children}</Content>
            </div>
          </div>
          <Footer />
        </div>
      </div>

      {/* begin:: Drawers */}
      <ActivityDrawer />
      <ExploreMain />
      <DrawerMessenger />
      {/* end:: Drawers */}

      {/* begin:: Modals */}
      <Main />
      <InviteUsers />
      <UpgradePlan />
      {/* end:: Modals */}

      <MasterInit />
      <ScrollTop />
    </PageDataProvider>
  );
};

export { MasterLayout };
