import React, { useState } from 'react';
// Libraries
import * as Yup from 'yup';
import clsx from 'clsx';
import { Link } from 'react-router-dom';
import { useFormik } from 'formik';
import { FormattedMessage, useIntl } from 'react-intl';
// i18n
import { useLang } from '_metronic/i18n/Metronici18n';
// Helpers
import { toAbsoluteUrl } from '_metronic/helpers';
// Services
import AuthService from 'app/services/http/endpoints/auth';
// Components
import Button from 'app/components/Button';
import CustomLink from 'app/components/CustomLink';

const initialValues = {
  email: '',
};

export const ForgotPassword: React.FC = () => {
  // states
  const [loading, setLoading] = useState(false);
  const [hasErrors, setHasErrors] = useState<boolean | undefined>(undefined);
  const [isEmailSent, setIsEmailSent] = useState(false);

  // Hooks
  const intl = useIntl();
  const lang = useLang();

  const ForgotPasswordSchema = Yup.object().shape({
    email: Yup.string()
      .email(intl.formatMessage({ id: 'VALIDATION.EMAIL.FIELD' }))
      .min(3, intl.formatMessage({ id: 'VALIDATION.MIN.FIELD' }, { number: 3 }))
      .required(intl.formatMessage({ id: 'VALIDATION.REQUIRED.FIELD' })),
  });

  const formik: any = useFormik({
    initialValues,
    validationSchema: ForgotPasswordSchema,
    onSubmit: (values, { setStatus, setSubmitting }) => {
      setLoading(true);
      setHasErrors(undefined);

      AuthService.resetPassword({ email: values.email }, lang)
        .then(() => {
          setHasErrors(false);
          setLoading(false);
          setIsEmailSent(true);
        })
        .catch(() => {
          setHasErrors(true);
          setLoading(false);
          setSubmitting(false);
          setStatus(intl.formatMessage({ id: 'AUTH.VALIDATION.NOT_FOUND' }, { name: values.email }));
        });
    },
  });

  return (
    <div className="login-form login-forgot" style={{ display: 'block' }}>
      {isEmailSent
      && (
      <div className="mb-14 text-center">
        <img alt="envelop" src={toAbsoluteUrl('/media/svg/envelope-open-text.svg')} />
        <div className="mt-20 mb-14 font-size-lg fw-bolder">
          <FormattedMessage id="AUTH.CHANGE.PASSWORD.EMAIL.IS.SENT" />
        </div>
        <Link
          to="/forgot-password"
          className="blue_link font-size-lg text-hover-primary my-3 mr-2"
          id="kt_login_forgot"
          onClick={(): void => setIsEmailSent(false)}
        >
          <FormattedMessage id="AUTH.CHANGE.PASSWORD.RECEIVE.LINK" />
        </Link>
        <div className="my-12">
          <Link to="/login">
            <Button
              type="button"
              id="kt_login_forgot_cancel"
              title="CANCEL"
              isLight
            />
          </Link>
        </div>
      </div>
      )}
      {!isEmailSent && (
      <form
        className="form w-100 fv-plugins-bootstrap5 fv-plugins-framework"
        noValidate
        id="kt_login_password_reset_form"
        onSubmit={formik.handleSubmit}
      >
        <div className="mb-14">
          {/* begin::Link */}
          <div className="fs-6"><FormattedMessage id="AUTH.CHANGE.PASSWORD.TITLE" /></div>
          {/* end::Link */}
        </div>

        {/* begin::Title */}
        {hasErrors === true && (
        <div className="mb-lg-15 alert alert-danger">
          <div className="alert-text font-weight-bold">
            <FormattedMessage id="SOME.ERRORS.DETECTED" />
          </div>
        </div>
        )}
        {/* end::Title */}

        {/* begin::Form group */}
        <div className="fv-row mb-10">
          {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
          <label className="form-label fs-6"><FormattedMessage id="AUTH.CHANGE.PASSWORD.LABEL" /></label>
          <input
            type="email"
            placeholder="someone@example.com"
            autoComplete="off"
			// eslint-disable-next-line react/jsx-props-no-spreading
            {...formik.getFieldProps('email')}
            className={clsx(
              'form-control form-control-lg form-control-solid',
              { 'is-invalid': formik.touched.email && formik.errors.email },
              {
                'is-valid': formik.touched.email && !formik.errors.email,
              },
            )}
          />
          {formik.touched.email && formik.errors.email && (
          <div className="fv-plugins-message-container">
            <div className="fv-help-block">
              <span role="alert" className="text-danger">{formik.errors.email}</span>
            </div>
          </div>
          )}
        </div>
        {/* end::Form group */}

        {/* begin::Form group */}
        <div className="d-flex flex-wrap justify-content-center justify-content-sm-between pb-lg-0">
          <CustomLink
            id="kt_login_password_reset_form_cancel_button"
            to="/login"
            className="mb-6"
            title="CANCEL"
            isLight
          />
          <Button
            className="ms-sm-4 mb-6"
            title="AUTH.CHANGE.PASSWORD.SUBMIT"
            type="submit"
            id="kt_password_reset_submit"
            disabled={formik.isSubmitting || !formik.isValid || formik.values.email === ''}
            loading={loading}
          />
        </div>
        {/* end::Form group */}
      </form>
      )}
    </div>
  );
};
