import React from 'react';
import clsx from 'clsx';
import { Link } from 'react-router-dom';
import { useLocation } from 'react-router';
import { checkIsActive, KTSVG } from '../../../helpers';
import { useLayout } from '../../core';

type Props = {
  to: string
  title: string
  icon?: string
  fontIcon?: string
  hasBullet?: boolean
  isSubMenu?: boolean
  FontAwsomeIcon?: JSX.Element
};

const AsideMenuItem: React.FC<Props> = ({
  children,
  to,
  title,
  icon,
  fontIcon,
  hasBullet = false,
  isSubMenu = false,
  FontAwsomeIcon,
}) => {
  const { pathname } = useLocation();
  const isActive = checkIsActive(pathname, to);
  const { config } = useLayout();
  const { aside } = config;

  return (
    <div className='menu-item'>
      <Link
        className={clsx('menu-link without-sub ', {
          active: isActive,
          'py-4': isSubMenu,
          'py-6': !isSubMenu,
        })}
        to={to}
      >
        {hasBullet && (
          <span className='menu-bullet'>
            <span className='bullet bullet-dot'></span>
          </span>
        )}
        {icon && aside.menuIcon === 'svg' && (
          <span className='menu-icon'>
            {FontAwsomeIcon ? FontAwsomeIcon : <KTSVG path={icon} className='svg-icon-2' />}
          </span>
        )}
        {fontIcon && aside.menuIcon === 'font' && <i className={clsx('bi fs-3', fontIcon)}></i>}
        <span className='menu-title'>{title}</span>
      </Link>
      {children}
    </div>
  );
};

export { AsideMenuItem };
